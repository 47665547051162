import React, { useState } from "react"
import loadable from "@loadable/component"
// import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "../../../templates/style.css"
import VideoPlay from "../../core/VideoPlay"
const Slider = loadable(() => import("react-slick"))

function NextArrow(props) {
  const { style, onClick } = props
  return (
    <div
      style={{ ...style, display: "block" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <button className="slick-next-arrow">
        <svg width="32" height="25" viewBox="0 0 32 25">
          <path
            fill="#3D3D3D"
            fillRule="evenodd"
            d="M32 12a1.22 1.22 0 0 0 0-.13v-.11a1.25 1.25 0 0 0 0-.13l-.05-.1-.07-.12-.07-.09-.08-.1L21 .39a1.3 1.3 0 0 0-1.82 0 1.25 1.25 0 0 0 0 1.79l8.43 8.56H1.29a1.3 1.3 0 0 0 0 2.59h26.42l-8.55 8.55a1.25 1.25 0 0 0 0 1.79 1.3 1.3 0 0 0 1.82 0L31.63 13l.08-.09.08-.09.06-.11.06-.11a1.18 1.18 0 0 0 0-.12 1.22 1.22 0 0 0 0-.12 1.16 1.16 0 0 0 0-.12 1.2 1.2 0 0 0 0-.13L32 12z"
          ></path>
        </svg>
      </button>
    </div>
  )
}

function PrevArrow(props) {
  const { style, onClick } = props
  return (
    <div
      style={{ ...style, display: "block" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <button className="slick-prev-arrow">
        <svg width="32" height="25" viewBox="0 0 32 25">
          <path
            fill="#3D3D3D"
            fillRule="evenodd"
            d="M0 12a1.22 1.22 0 0 1 0-.13v-.11a1.25 1.25 0 0 1 0-.13l.05-.1.07-.12.07-.09.08-.1L11 .39a1.3 1.3 0 0 1 1.82 0 1.25 1.25 0 0 1 0 1.79l-8.43 8.56h26.32a1.3 1.3 0 0 1 0 2.59H4.29l8.55 8.55a1.25 1.25 0 0 1 0 1.79 1.3 1.3 0 0 1-1.82 0L.37 13l-.08-.09-.08-.09-.06-.11-.06-.11a1.18 1.18 0 0 1 0-.12 1.22 1.22 0 0 1 0-.12 1.16 1.16 0 0 1 0-.12 1.2 1.2 0 0 1 0-.13L0 12z"
          ></path>
        </svg>
      </button>
    </div>
  )
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: false,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  focusOnSelect: true,
  arrows: true,
  resumeOnVisible: true,
  useTransform: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
}

export const NewVideoLinks = ({ VideoLinksBlock }) => {
  // console.log(VideoLinksBlock)
  const { videoLinks } = VideoLinksBlock
  const [isOpen, setIsOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState(null)

  const handleVideo = video => {
    document.body.classList.add("overflow-hidden")
    setIsOpen(true)
    setVideoUrl(video?.link)
  }

  const handleCloseVideo = () => {
    setIsOpen(false)
    document.body.classList.remove("overflow-hidden")
  }

  return (
    <div>
      {isOpen && (
        <VideoPlay videoUrl={videoUrl} handleCloseVideo={handleCloseVideo} />
      )}
      <div className="max-w-screen-xl mx-auto lg:p-12 p-1">
        <div className="slider-btn">
          <Slider
            {...settings}
            className="custom-slick-slider slider slider__container mx-auto relative"
          >
            {videoLinks.map(e => (
              <div className="p-4" key={Math.random()}>
                <div
                  className="min-w-full relative  transform shadow-md hover:shadow-xl hover:-translate-y-2  rounded-md duration-300 ease-in-out cursor-pointer"
                  key={Math.random()}
                  onClick={() => handleVideo(e)}
                  aria-hidden="true"
                >
                  <div className="imgWrapper m-auto max-w-full relative ">
                    <img
                      src={e.poster?.mediaItemUrl}
                      width={e.poster?.mediaDetails?.width}
                      height={e.poster?.mediaDetails?.height}
                      alt="Video-Poster"
                      // title="Video-Poster"
                      className="w-full h-auto object-cover rounded-md"
                      loading="lazy"
                    />
                    <img
                      src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/05/Play-Button.png"
                      alt="Play-Button"
                      width="80px"
                      height="80px"
                      // title="Play-Button"
                      className="absolute bottom-2 z-10 rounded-full  w-16  right-2"
                      loading="lazy"
                    />
                    <img
                      src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/Quote.png"
                      alt="Quote"
                      width="64px"
                      height="64px"
                      // title="Quote"
                      className="w-10 absolute -bottom-5 -left-4 z-10"
                      loading="lazy"
                    />
                  </div>
                  <div
                    className="md:px-6 px-4 py-3  cursor-pointer h-24"
                    onClick={() => handleVideo(e)}
                    aria-hidden="true"
                  >
                    <p className="text-xl font-semibold text-shark-500">
                      {e.title}
                    </p>
                    <p className="text-sm font-sans text-shark-400 font-medium ">
                      {e.subtitle}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default NewVideoLinks
